import React, { FC } from 'react';

import BrandHistoryCard from 'gatsby-theme-gaviscon/src/components/BrandHistoryCard';
import ControlledCarousel from 'gatsby-theme-gaviscon/src/components/ControlledCarousel';

import useScreenRecognition from 'gatsby-theme-gaviscon/src/hooks/useScreenRecognition';
import responsiveObj from './constants';
import { IBrandHistoryProps } from 'gatsby-theme-gaviscon/src/components/BrandHistory/model';

import 'gatsby-theme-gaviscon/src/components/BrandHistory/BrandHistory.scss';
import './BrandHistoryOverride.scss';

const BrandHistory: FC<IBrandHistoryProps> = ({ history }) => {
  const { isMobile } = useScreenRecognition();

  return (
    <div className="gs-history">
      <ControlledCarousel
        dotsOutside
        navigatorsOutside={!isMobile}
        showDots
        classes="gs-history-carousel"
        responsiveObj={responsiveObj}
        showAllDots
        customDots={history.map(({ properties: historyCard }) => ({
          year: historyCard.year,
          ariaDot: historyCard.ariaDot,
        }))}
      >
        {history.map(({ properties: historyCard }, index) => {
          const id = `${historyCard.year}_${index}`;

          return <BrandHistoryCard key={id} {...historyCard} />;
        })}
      </ControlledCarousel>
    </div>
  );
};
export default BrandHistory;
